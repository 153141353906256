import { FC } from "react";
import MobileFriendlyIcon from "@mui/icons-material/MobileFriendly";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import AssessmentIcon from "@mui/icons-material/Assessment";

// interface
interface Benefit {
  title: string;
  description: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  icon: FC<{}>;
}

export const benefits: Array<Benefit> = [
  {
    title: "Mobile-Optimized: Great On Every Device",
    description:
      "Every design includes a unique mobile experience that matches the overall style of your website.",
    icon: MobileFriendlyIcon,
  },
  {
    title: "Make More Sales",
    description:
      "Reach more customers than ever with shoppable Instagram posts that pull from your product inventory.",
    icon: MonetizationOnIcon,
  },
  {
    title: "Email Campaigns",
    description:
      "Connect with your audience through emails that match the style of your site.",
    icon: MailOutlineIcon,
  },
  {
    title: "Analytics: Optimize Your Business",
    description:
      "Understand your customers and their interactions with your site through KioskKit Analytics.",
    icon: AssessmentIcon,
  },
];
