import React, { useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useAddToWaitingListMutation } from "../../app/services/subscription";
import { toast } from "react-toastify";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod/dist/zod";
import { object, string, TypeOf } from "zod";

const subscribeSchema = object({
  email: string().min(3, "Email address is required").email("Email is invalid"),
});

export type EmailInput = TypeOf<typeof subscribeSchema>;

const JoinUsLater = () => {
  const {
    reset,
    register,
    handleSubmit,
    formState: { isSubmitSuccessful, errors },
  } = useForm<EmailInput>({
    resolver: zodResolver(subscribeSchema),
  });

  const fieldName = errors["email"] as any;

  // API News Subscription Mutation
  const [addToWaitingList, { isLoading, isError, error, isSuccess }] =
    useAddToWaitingListMutation();

  useEffect(() => {
    if (isSuccess) {
      toast.success("You were added to the waiting list.");
    }
    if (isError) {
      if (Array.isArray((error as any).data.error)) {
        (error as any).data.error.forEach((el: any) =>
          toast.error(el.message, {
            position: "top-right",
          }),
        );
      } else if (error !== null) {
        toast.error((error as any).data.message, {
          position: "top-right",
        });
      } else {
      }
    }
  }, [isLoading]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful]);

  const onSubmitHandler: SubmitHandler<EmailInput> = (values) => {
    addToWaitingList(values);
  };

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item>
        <Box
          sx={{
            padding: "15%",
          }}
        >
          <Typography
            component="div"
            variant="h4"
            sx={{
              fontWeight: "bold",
              marginBottom: 2,
              color: "#ff5722",
              paddingBottom: "30px",
              textAlign: "center",
            }}
          >
            Thank you for visiting!
          </Typography>
          <Typography
            sx={{
              marginBottom: 1,
              color: "#424242",
              paddingBottom: "30px",
              textAlign: "center",
            }}
          >
            Our website is currently under development, and we're working hard
            to bring you an enhanced and exciting experience.
          </Typography>
          <Typography
            variant="h5"
            sx={{
              marginBottom: 1,
              color: "#424242",
              paddingBottom: "30px",
              textAlign: "center",
            }}
          >
            Stay tuned for updates, and we appreciate your patience.
          </Typography>
          <form onSubmit={handleSubmit(onSubmitHandler)} className="form-width">
            <FormControl
              component="fieldset"
              variant="filled"
              fullWidth={true}
              sx={{
                flexDirection: "row",
              }}
            >
              <TextField
                placeholder="Email"
                variant="outlined"
                inputMode="email"
                color="warning"
                hiddenLabel
                autoComplete="off"
                focused
                {...register("email")}
                fullWidth
                error={!!errors["email"]}
                sx={{
                  justifyContent: "end",
                }}
              />

              <Button
                type="submit"
                variant="contained"
                size="large"
                sx={{
                  marginLeft: "10px",
                  whiteSpace: "nowrap",
                  fontWeight: "bold",
                }}
              >
                Notify Me
              </Button>
            </FormControl>

            <FormHelperText error={!!errors["email"]}>
              {fieldName ? fieldName.message : ""}
            </FormHelperText>
          </form>
        </Box>
      </Grid>
    </Grid>
  );
};

export default JoinUsLater;
