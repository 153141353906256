import Register from "./feature/User/Register";
import Login from "./feature/User/Login";

// other
import { FC } from "react";

// interface
interface Route {
  key: string;
  title: string;
  path: string;
  enabled: boolean;
  component: FC;
}

export const routes: Array<Route> = [
  {
    key: "register-route",
    title: "Join us",
    path: "/register",
    enabled: true,
    component: Register,
  },
  {
    key: "login-route",
    title: "Login",
    path: "/login",
    enabled: true,
    component: Login,
  },
];
