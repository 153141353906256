import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { object, string, TypeOf } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Button, Grid, Typography } from "@mui/material";
import FormInput from "../../components/Shared/FormInput";
import { useLoginUserMutation } from "../../app/services/auth";
import LinkItem from "../../components/Shared/LinkItem";

const loginSchema = object({
  email: string().min(1, "Email address is required").email("Email is invalid"),
  password: string()
    .min(1, "Password is required")
    .min(8, "Password must be more than 8 characters")
    .max(32, "Password must be less than 32 characters"),
});

export type LoginInput = TypeOf<typeof loginSchema>;

const Login = () => {
  const methods = useForm<LoginInput>({
    resolver: zodResolver(loginSchema),
  });

  // API Login Mutation
  const [loginUser, { isLoading, isError, error, isSuccess }] =
    useLoginUserMutation();

  const navigate = useNavigate();
  const location = useLocation();

  const from =
    ((location.state as any)?.from.pathname as string) || "/dashboard";

  const {
    reset,
    handleSubmit,
    formState: { isSubmitSuccessful },
  } = methods;

  useEffect(() => {
    if (isSuccess) {
      toast.success("You successfully logged in");
      navigate(from);
    }
    if (isError) {
      if (Array.isArray((error as any).data.error)) {
        (error as any).data.error.forEach((el: any) =>
          toast.error(el.message, {
            position: "top-right",
          }),
        );
      } else {
        toast.error((error as any).data.message, {
          position: "top-right",
        });
      }
    }
  }, [isLoading]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful]);

  const onSubmitHandler: SubmitHandler<LoginInput> = (values) => {
    // Executing the loginUser Mutation
    loginUser(values);
  };

  return (
    <Grid
      container
      sx={{
        marginTop: 5,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        marginBottom: 5,
      }}
    >
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <Typography
          component="h1"
          sx={{
            textAlign: "center",
            color: "primary.main",
            fontWeight: 600,
            fontSize: { xs: "2rem", md: "3rem" },
            mb: 2,
            letterSpacing: 1,
          }}
        >
          Login to your account
        </Typography>
        <Typography textAlign="center" sx={{ fontSize: "0.9rem", mb: "1rem" }}>
          Need an account? <LinkItem to="/register">Join Us</LinkItem>
        </Typography>
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{ width: "40rem" }}>
        <FormProvider {...methods}>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmitHandler)}
            noValidate
            autoComplete="off"
            sx={{
              p: { xs: "1rem", sm: "2rem" },
              borderRadius: 2,
            }}
          >
            <FormInput name="email" label="Email" type="email" />
            <FormInput name="password" label="Password" type="password" />

            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                alignItems: "end",
              }}
            >
              <Typography
                sx={{
                  fontSize: "1rem",
                  mb: "1rem",
                  alignContent: "right",
                }}
              >
                <LinkItem to="/forgot-password">Forgot a password?</LinkItem>
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                alignItems: "end",
              }}
            >
              <Button
                variant="contained"
                fullWidth
                size="large"
                type="submit"
                sx={{
                  width: "30%",
                  whiteSpace: "nowrap",
                  fontWeight: "bold",
                }}
              >
                Login
              </Button>
            </Box>
          </Box>
        </FormProvider>
      </Grid>
    </Grid>
  );
};

export default Login;
