import React from "react";
import MyImage from "../../images/18.webp";
import { Box, Typography } from "@mui/material";

const Starter: React.FC<{ onClick: any }> = ({ onClick }) => {
  return (
    <Box
      sx={{
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        backgroundColor: "whitesmoke",
        transform: "scale",
        height: "100vh",
        background: `linear-gradient(10deg, rgba(255, 255, 255, 0.42), rgba(0, 0, 0, 0.91)), url(${MyImage}) center/cover`,
      }}
    >
      <Typography
        component="div"
        variant="h3"
        sx={{
          padding: "10% 15% 15% 15%",
          textAlign: "center",
          fontWeight: "bold",
          color: "whitesmoke",
        }}
      >
        Empower your brand with KioskKit - Your Marketing Partner
      </Typography>
      <a
        className="ca3-scroll-down-link ca3-scroll-down-arrow"
        key="goToBenefit"
        data-ca3_iconfont="ETmodules"
        data-ca3_icon=""
        onClick={onClick}
      ></a>
    </Box>
  );
};

export default Starter;
