import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setUser } from "../../feature/User/userSlice";

const BASE_URL = process.env.REACT_APP_API_ENDPOINT as string;

export type User = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  roles: [string];
};

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}`,
  }),
  tagTypes: ["User"],
  endpoints: (builder) => ({
    getMe: builder.query<User, null>({
      query() {
        return {
          url: "/v1/users/me",
          credentials: "include",
        };
      },
      transformResponse: (result: { user: User }) => result.user,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setUser(data));
        } catch (error) {}
      },
    }),
  }),
});
