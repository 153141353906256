import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { EmailInput } from "../../components/Landing/Landing";

const BASE_URL = process.env.REACT_APP_API_ENDPOINT as string;

export const subscriptionApi = createApi({
  reducerPath: "subscriptionApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}`,
  }),
  endpoints: (builder) => ({
    addToWaitingList: builder.mutation<string, EmailInput>({
      query(data) {
        return {
          url: "/v1/subscribers",
          method: "POST",
          body: data,
        };
      },
    }),
  }),
});

export const { useAddToWaitingListMutation } = subscriptionApi;
