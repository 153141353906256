import React from "react";
import { Container } from "@mui/material";
import { Outlet } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import AppFooter from "../AppFooter/AppFooter";
import { ThemeProvider } from "@mui/material";
import { theme } from "../../theme";
import "react-toastify/dist/ReactToastify.css";

const HomeLayout = () => {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Navbar />
        <Container disableGutters>
          <Outlet />
        </Container>
        <AppFooter />
      </ThemeProvider>
    </div>
  );
};

export default HomeLayout;
