import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import SearchField from "../../components/Shared/SearchField";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Preview from "../Business/PreviewItem/Preview";
import { businessList } from "../Business/businessList";

export default function Dashboard() {
  const isAuthenticated = localStorage.getItem("token");
  const navigate = useNavigate();

  if (isAuthenticated) {
    navigate("/login");
  }

  return (
    <Box sx={{ display: "flex" }}>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                paddingBottom: "15px",
              }}
            >
              My Dashboard
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <SearchField />
            <IconButton
              sx={{
                color: "primary.main",
                display: { xs: "block", sm: "block", md: "none" },
                fontSize: "large",
              }}
            >
              <AddCircleIcon />
            </IconButton>
            <Button
              variant="contained"
              href="/business/create"
              sx={{
                color: "white",
                display: { xs: "none", sm: "none", md: "block" },
                marginLeft: "10px",
              }}
            >
              Add business
            </Button>
          </Grid>
          {businessList.map((page: any) => (
            <Grid
              item
              key={page.title}
              xs={12}
              sx={{
                display: "flex",
                padding: "20px 0 10px 0",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Preview page={page} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}
