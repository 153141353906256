import React from "react";
import { Box, CardContent, Grid, Typography } from "@mui/material";
import { benefits } from "./benefits";

const Benefit = React.forwardRef((props, ref) => {
  return (
    <Box
      ref={ref}
      key="benefits"
      sx={{
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        backgroundColor: "whitesmoke",
        transform: "scale",
      }}
    >
      <Grid
        container
        spacing={1}
        sx={{
          width: "75%",
          padding: "100px 0 100px 0",
        }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{
            textAlign: "center",
            paddingBottom: 2,
          }}
        >
          <Typography
            variant="h4"
            component="div"
            sx={{
              fontWeight: "bold",
              color: "primary.main",
              textAlign: "center",
            }}
          >
            Start a successful online business with our powerful tools
          </Typography>
          <Typography
            component="div"
            variant="h5"
            sx={{
              display: "flex",
              padding: "30px 30px 0 30px",
              textAlign: "center",
            }}
          >
            KioskKit’s all-in-one platform gives you everything you need to make
            your business webpage stand out. No matter the stage of your online
            business, you can launch today using our powerful platform.
          </Typography>
        </Grid>
        {benefits.map((benefit) => (
          <Grid item key={benefit.title} xs={12} sm={6} md={6} lg={6} xl={6}>
            <Box key={benefit.title}>
              <Box
                key={benefit.title}
                sx={{
                  width: 20,
                  height: 20,
                  margin: 2,
                  color: "primary.main",
                }}
              >
                {<benefit.icon />}
              </Box>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {benefit.title}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    textAlign: "justify",
                  }}
                >
                  {benefit.description}
                </Typography>
              </CardContent>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
});

export default Benefit;
