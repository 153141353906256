import React from "react";
import {
  Box,
  CardMedia,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { steps } from "./steps";
import Category from "../Categories/Category";
import businessImg from "../../../images/9142.jpg";
import Contacts from "../Contacts/Contacts";
import Logo from "../Logo/Logo";
import BusinessLocation from "../Location/Location";
import BusinessType from "../BusinessType";
import GeneralInfo from "../GeneralInfo/GeneralInfo";

const Create = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const lastStepNumber = steps.length - 1;

  /*const business = useAppSelector((state) => state.businessState.business);

  useEffect(() => {
    if (business !== null) {
      const stepName = business.registrationStep;
      setActiveStep(steps.findIndex((element) => element.title === stepName));
    }
  });*/

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSteps = (step: number) => {
    switch (step) {
      case 0:
        return (
          <GeneralInfo
            activeStep={activeStep}
            lastStep={lastStepNumber}
            handleNext={handleNext}
          />
        );
      case 1:
        return (
          <BusinessType
            activeStep={activeStep}
            lastStep={lastStepNumber}
            handleNext={handleNext}
          />
        );
      case 2:
        return (
          <Logo
            activeStep={activeStep}
            lastStep={lastStepNumber}
            handleNext={handleNext}
          />
        );
      case 3:
        return (
          <BusinessLocation
            activeStep={activeStep}
            lastStep={lastStepNumber}
            handleNext={handleNext}
          />
        );
      case 4:
        return (
          <Contacts
            activeStep={activeStep}
            lastStep={lastStepNumber}
            handleNext={handleNext}
          />
        );
      case 5:
        return (
          <Category
            activeStep={activeStep}
            lastStep={lastStepNumber}
            handleNext={handleNext}
          />
        );
      default:
        throw new Error("Unknown step");
    }
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <CardMedia component="img" height="550" image={businessImg} />
        </Grid>
        <Grid item xs={12} sm={8}>
          <Box
            key="steps"
            sx={{
              margin: "0 40px 40px 40px",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                paddingBottom: "40px",
                textAlign: "center",
                textTransform: "uppercase",
              }}
            >
              Get your business discovered on KioskKit
            </Typography>
            <Stepper activeStep={activeStep}>
              {steps.map((step, index) => (
                <Step key={step.title}>
                  <StepLabel>{/*{step.title}*/}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Box>{handleSteps(activeStep)}</Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Create;
