import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { authApi } from "./services/auth";
import { userApi } from "./services/user";
import { subscriptionApi } from "./services/subscription";
import userReducer from "../feature/User/userSlice";
import businessReducer from "../feature/Business/businessSlice";
import { businessApi } from "./services/business";
import { addressApi } from "./services/address";
import { categoryApi } from "./services/categories";

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [subscriptionApi.reducerPath]: subscriptionApi.reducer,
    [businessApi.reducerPath]: businessApi.reducer,
    [addressApi.reducerPath]: addressApi.reducer,
    [categoryApi.reducerPath]: categoryApi.reducer,
    userState: userReducer,
    businessState: businessReducer,
  },
  devTools: process.env.NODE_ENV === "development",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({}).concat([
      authApi.middleware,
      userApi.middleware,
      subscriptionApi.middleware,
      businessApi.middleware,
      addressApi.middleware,
      categoryApi.middleware,
    ]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
