import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { object, string, TypeOf } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Button, Grid, Typography } from "@mui/material";
import FormInput from "../../components/Shared/FormInput";
import { useRegisterUserMutation } from "../../app/services/auth";
import LinkItem from "../../components/Shared/LinkItem";

const registerSchema = object({
  firstName: string().min(1, "Firstname is required").max(100),
  lastName: string().min(1, "Lastname is required").max(100),
  email: string().min(1, "Email address is required").email("Email is invalid"),
  password: string()
    .min(1, "Password is required")
    .min(8, "Password must be more than 8 characters")
    .max(32, "Password must be less than 32 characters"),
  passwordConfirm: string().min(1, "Please confirm your password"),
}).refine((data) => data.password === data.passwordConfirm, {
  path: ["passwordConfirm"],
  message: "Passwords do not match",
});

export type RegisterInput = TypeOf<typeof registerSchema>;

const Register = () => {
  const methods = useForm<RegisterInput>({
    resolver: zodResolver(registerSchema),
  });

  // Calling the Register Mutation
  const [registerUser, { isLoading, isSuccess, error, isError }] =
    useRegisterUserMutation();

  const navigate = useNavigate();

  const {
    reset,
    handleSubmit,
    formState: { isSubmitSuccessful },
  } = methods;

  useEffect(() => {
    if (isSuccess) {
      toast.success("User registered successfully");
      navigate("/confirm-email");
    }

    if (isError) {
      if (Array.isArray((error as any).data.error)) {
        (error as any).data.error.forEach((el: any) =>
          toast.error(el.message, {
            position: "top-right",
          }),
        );
      } else {
        toast.error((error as any).data.message, {
          position: "top-right",
        });
      }
    }
  }, [isLoading]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful]);

  const onSubmitHandler: SubmitHandler<RegisterInput> = (values) => {
    // ? Executing the RegisterUser Mutation
    registerUser(values);
  };

  return (
    <Grid
      container
      direction="row"
      sx={{
        marginTop: 5,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        marginBottom: 5,
      }}
    >
      <Typography
        component="h1"
        sx={{
          textAlign: "center",
          color: "primary.main",
          fontSize: { xs: "2rem", md: "3rem" },
          fontWeight: 600,
          mb: 2,
          letterSpacing: 1,
        }}
      >
        Create an account
      </Typography>
      <Typography sx={{ fontSize: "0.9rem", mb: "1rem" }}>
        Already have an account? <LinkItem to="/login">Login here</LinkItem>
      </Typography>
      <FormProvider {...methods}>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmitHandler)}
          noValidate
          autoComplete="off"
          sx={{
            maxWidth: "40rem",
            width: "100%",
            p: { xs: "1rem", sm: "2rem" },
            borderRadius: 2,
          }}
        >
          <FormInput name="firstName" label="First name" required />
          <FormInput name="lastName" label="Last name" required />
          <FormInput name="email" label="Email" type="email" required />
          <FormInput
            name="password"
            label="Password"
            type="password"
            required
          />
          <FormInput
            name="passwordConfirm"
            label="Password Confirm"
            type="password"
            required
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "end",
            }}
          >
            <Button
              variant="contained"
              fullWidth
              size="large"
              type="submit"
              sx={{
                width: "30%",
                whiteSpace: "nowrap",
                fontWeight: "bold",
              }}
            >
              create
            </Button>
          </Box>
        </Box>
      </FormProvider>
    </Grid>
  );
};

export default Register;
