import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";

const LinkItem = styled(Link)`
  text-decoration: none;
  color: #eb5e28;
  &:hover {
    text-decoration: underline;
  }
`;

export default LinkItem;
