import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, Grid, Typography } from "@mui/material";
import { object, string, TypeOf } from "zod";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useResetPasswordMutation } from "../../app/services/auth";
import { toast } from "react-toastify";
import FormInput from "../../components/Shared/FormInput";

const passwordRecoverySchema = object({
  otp: string().min(1, "Verification code is required"),
  password: string()
    .min(1, "Password is required")
    .min(8, "Password must be more than 8 characters")
    .max(32, "Password must be less than 32 characters"),
  confirm_password: string().min(1, "Please confirm your password"),
}).refine((data) => data.password === data.confirm_password, {
  path: ["confirm_password"],
  message: "Passwords do not match",
});

export type PasswordRecoveryInput = TypeOf<typeof passwordRecoverySchema>;

const PasswordRecovery = () => {
  const { otp } = useParams();

  const methods = useForm<PasswordRecoveryInput>({
    resolver: zodResolver(passwordRecoverySchema),
  });

  // API Login Mutation
  const [resetPassword, { isLoading, isSuccess, data, isError, error }] =
    useResetPasswordMutation();

  const navigate = useNavigate();

  const {
    reset,
    handleSubmit,
    formState: { isSubmitSuccessful },
  } = methods;

  useEffect(() => {
    if (otp) {
      reset({ otp });
    }
  }, []);

  useEffect(() => {
    if (isSuccess) {
      toast.success(data?.message);
      navigate("/login");
    }
    if (isError) {
      if (Array.isArray((error as any).data.error)) {
        (error as any).data.error.forEach((el: any) =>
          toast.error(el.message, {
            position: "top-right",
          }),
        );
      } else {
        toast.error((error as any).data.message, {
          position: "top-right",
        });
      }
    }
  }, [isLoading]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful]);

  const onSubmitHandler: SubmitHandler<PasswordRecoveryInput> = ({
    otp,
    password,
    confirm_password,
  }) => {
    resetPassword({ otp, password, confirm_password });
  };

  return (
    <Grid
      container
      direction="row"
      sx={{
        marginTop: 5,
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        marginBottom: 5,
      }}
    >
      <Grid item>
        <Typography
          component="h1"
          sx={{
            textAlign: "center",
            color: "primary.main",
            fontSize: { xs: "2rem", md: "3rem" },
            fontWeight: 600,
            mb: 2,
            letterSpacing: 1,
          }}
        >
          Reset your password
        </Typography>
        <FormProvider {...methods}>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmitHandler)}
            noValidate
            autoComplete="off"
            maxWidth="40rem"
            width="100%"
            sx={{
              p: { xs: "1rem", sm: "2rem" },
              borderRadius: 2,
            }}
          >
            <FormInput
              name="password"
              label="Password"
              type="password"
              required
            />
            <FormInput
              name="confirm_password"
              label="Confirm Password"
              type="password"
              required
            />

            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                alignItems: "end",
              }}
            >
              <Button
                variant="contained"
                fullWidth
                size="large"
                type="submit"
                sx={{
                  width: "30%",
                  whiteSpace: "nowrap",
                  fontWeight: "bold",
                }}
              >
                Recover
              </Button>
            </Box>
          </Box>
        </FormProvider>
      </Grid>
    </Grid>
  );
};

export default PasswordRecovery;
