import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { Business } from "../../app/services/business";

type BusinessState = {
  business: Business | null;
};

const initialState: BusinessState = {
  business: null,
};

export const businessSlice = createSlice({
  initialState,
  name: "businessSlice",
  reducers: {
    setBusiness: (state, action: PayloadAction<Business>) => {
      state.business = action.payload;
    },
  },
});

export default businessSlice.reducer;

export const { setBusiness } = businessSlice.actions;
