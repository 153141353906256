import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { object, string, TypeOf } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Button, Grid, Typography } from "@mui/material";
import FormInput from "../../components/Shared/FormInput";
import { useVerifyEmailMutation } from "../../app/services/auth";

const verificationCodeSchema = object({
  email: string().min(1, "Email is required"),
  otp: string().min(1, "Verification code is required"),
});

export type VerificationCodeInput = TypeOf<typeof verificationCodeSchema>;

const EmailVerification = () => {
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const email = query.get("email");
  const otp = query.get("otp");

  const methods = useForm<VerificationCodeInput>({
    resolver: zodResolver(verificationCodeSchema),
  });

  // API Login Mutation
  const [verifyEmail, { isLoading, isSuccess, data, isError, error }] =
    useVerifyEmailMutation();

  const navigate = useNavigate();

  const {
    reset,
    handleSubmit,
    formState: { isSubmitSuccessful },
  } = methods;

  useEffect(() => {
    if (email) {
      if (otp) {
        reset({ email, otp });
      }
    }
  }, []);

  useEffect(() => {
    if (isSuccess) {
      toast.success(data?.message);
      navigate("/login");
    }
    if (isError) {
      if (Array.isArray((error as any).data.error)) {
        (error as any).data.error.forEach((el: any) =>
          toast.error(el.message, {
            position: "top-right",
          }),
        );
      } else {
        toast.error((error as any).data.message, {
          position: "top-right",
        });
      }
    }
  }, [isLoading]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful]);

  const onSubmitHandler: SubmitHandler<VerificationCodeInput> = ({
    email,
    otp,
  }) => {
    // Executing the verifyEmail Mutation
    verifyEmail({ email, otp });
  };

  return (
    <Grid
      container
      direction="row"
      sx={{
        marginTop: 5,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Typography
        component="h1"
        sx={{
          textAlign: "center",
          color: "primary.main",
          fontWeight: 600,
          fontSize: { xs: "2rem", md: "3rem" },
          mb: 2,
          letterSpacing: 1,
        }}
      >
        Verify Email Address
      </Typography>

      <FormProvider {...methods}>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmitHandler)}
          noValidate
          autoComplete="off"
          sx={{
            maxWidth: "27rem",
            width: "100%",
            p: { xs: "1rem", sm: "2rem" },
            borderRadius: 2,
          }}
        >
          <FormInput name="email" label="Email" disabled={true} />
          <FormInput name="otp" label="Verification Code" />

          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "end",
            }}
          >
            <Button
              variant="contained"
              fullWidth
              size="large"
              type="submit"
              sx={{
                width: "30%",
                whiteSpace: "nowrap",
                fontWeight: "bold",
              }}
            >
              Verify
            </Button>
          </Box>
        </Box>
      </FormProvider>
    </Grid>
  );
};

export default EmailVerification;
