import * as React from "react";
import {
  Card,
  CardMedia,
  Box,
  CardActions,
  Typography,
  Button,
  Link,
} from "@mui/material";
import VerticalMenu from "../VerticalMenu/VerticalMenu";
import "../business.css";

interface WebPage {
  title: string;
  subtitle: string;
  image: string;
  altImage: string;
  description: string;
}

const Preview = ({ page }: { page: WebPage }) => {
  return (
    <Card sx={{ width: "100%", display: "flex" }}>
      <Link
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "30%",
          alignSelf: "flex-start",
          flexShrink: 0,
          backgroundColor: "rgb(242, 242, 242)",
        }}
      >
        <Button
          className="go-to-page-btn"
          variant="contained"
          sx={{
            position: "absolute",
            opacity: 0,
          }}
        >
          Go To Webpage
        </Button>
        <CardMedia
          component="img"
          //image={page.image}
          //alt={page.altImage}
          sx={{
            height: "190px",
            backgroundImage: `url(${page.image})`,
            backgroundRepeat: "no-repeat",
          }}
        />
      </Link>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          padding: "0px 33px",
        }}
      >
        <Link
          sx={{
            fontWeight: 500,
            textDecoration: "none",
            textOverflow: "ellipsis",
            paddingTop: "33px",
            cursor: "pointer",
          }}
        >
          {page.title}
        </Link>
        <Typography variant="subtitle1" color="text.secondary" component="div">
          {page.subtitle}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "left", pb: 1 }}>
          <Typography variant="body2" color="text.secondary">
            {page.description}
          </Typography>
        </Box>
      </Box>
      <CardActions sx={{ alignItems: "start" }}>
        <Box sx={{ pb: 1 }}>
          <VerticalMenu />
        </Box>
      </CardActions>
    </Card>
  );
};

export default Preview;
