import React from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import StepButton from "../../components/Shared/StepButton";

const BusinessType = ({ activeStep, lastStep, handleNext }) => {
  return (
    <Box
      height="400px"
      sx={{
        position: "relative",
        paddingTop: "30px",
        margin: "0 20px 0 20px",
      }}
    >
      <Grid>
        <Grid item>
          <Typography
            sx={{
              fontSize: "20px",
            }}
          >
            Choose your business type
          </Typography>
          <Typography
            sx={{
              paddingBottom: "20px",
            }}
          >
            Select all that apply
          </Typography>
        </Grid>
        <Grid item>
          <FormControlLabel
            control={<Checkbox id="label-1" />}
            label={
              <Box
                sx={{
                  height: "80px",
                  width: "100%",
                  paddingTop: "15px",
                }}
              >
                <Typography
                  sx={{
                    textTransform: "capitalize",
                    fontWeight: 900,
                    letterSpacing: "1px",
                    cursor: "pointer",
                    transition: "all 0.7s ease",
                  }}
                >
                  Online retail
                </Typography>
                <span>
                  Customers can purchase products through your website
                </span>
              </Box>
            }
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={<Checkbox id="label-2" />}
            label={
              <Box
                sx={{
                  height: "80px",
                  width: "100%",
                  paddingTop: "15px",
                }}
              >
                <Typography
                  sx={{
                    textTransform: "capitalize",
                    fontWeight: 900,
                    letterSpacing: "1px",
                    cursor: "pointer",
                    transition: "all 0.7s ease",
                  }}
                >
                  Local store
                </Typography>
                <span>Customers can visit your business in person</span>
              </Box>
            }
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={<Checkbox id="label-3" />}
            label={
              <Box
                sx={{
                  height: "80px",
                  width: "100%",
                  paddingTop: "15px",
                }}
              >
                <Typography
                  sx={{
                    textTransform: "capitalize",
                    fontWeight: 900,
                    letterSpacing: "1px",
                    cursor: "pointer",
                    transition: "all 0.7s ease",
                  }}
                >
                  Service business
                </Typography>
                <span>Your business makes visit to customers</span>
              </Box>
            }
          />
        </Grid>
      </Grid>
      <StepButton activeStep={activeStep} lastStep={lastStep} />
    </Box>
  );
};

export default BusinessType;
