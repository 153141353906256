import React, { useEffect, useState } from "react";
import { Avatar, Grid, TextField, Typography } from "@mui/material";
import { useParams } from "react-router-dom";

const BASE_URL = process.env.REACT_APP_API_ENDPOINT;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

const BusinessDetails = () => {
  const params = useParams();
  const id = params["id"];

  const [businessDetails, setBusinessDetails] = useState({
    logo: "",
    name: "",
    address: { fullAddress: "" },
    categories: [{ id: "", name: "" }],
    contact: { email: "", phone: "", webUrl: "" },
  });

  useEffect(() => {
    // Fetch business details from the web API
    fetch(`${BASE_URL}/v1/businesses/${id}`, {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => setBusinessDetails(data))
      .catch((error) => console.error(error));
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setBusinessDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={3}>
        <Avatar
          alt="Business Logo"
          src={businessDetails.logo}
          style={{ width: 120, height: 120 }}
        />
      </Grid>
      <Grid item xs={12} sm={9}>
        <Typography variant="h5">{businessDetails.name}</Typography>
        <TextField
          variant="standard"
          name="address"
          label="Address"
          value={businessDetails.address.fullAddress}
          onChange={handleInputChange}
          fullWidth
        />
        {/*<Select
          labelId="multiple-chip-label"
          id="multiple-chip"
          multiple
          value={businessDetails.categories}
          name="category"
          onChange={handleInputChange}
          input={<Input id="select-multiple-chip" fullWidth />}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {businessDetails.categories.map((sc) => (
            <MenuItem key={sc.id} value={sc.name}>
              {sc.name}
            </MenuItem>
          ))}
        </Select>*/}
        <TextField
          variant="standard"
          name="webpage"
          label="Web Page"
          value={businessDetails.contact.webUrl}
          onChange={handleInputChange}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

export default BusinessDetails;
