import React, { useEffect } from "react";
import {
  Box,
  FormControl,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import StepButton from "../../../components/Shared/StepButton";
import { object, string, TypeOf } from "zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "react-toastify";
import { useAddGeneralInfoMutation } from "../../../app/services/business";

const generalInfoSchema = object({
  name: string().min(1, "Full Name is required"),
  shortName: string().min(1, "Short Name is required"),
  urlPath: string().min(1, "URL is required"),
  description: string().min(1, "Description is required.").max(1000),
});

export type GeneralInfoInput = TypeOf<typeof generalInfoSchema>;

interface IGeneralInfoProps {
  activeStep: number;
  lastStep: number;
  handleNext: () => void;
}

const GeneralInfo = ({
  activeStep,
  lastStep,
  handleNext,
}: IGeneralInfoProps) => {
  const {
    reset,
    register,
    handleSubmit,
    formState: { isSubmitSuccessful, errors },
  } = useForm<GeneralInfoInput>({
    resolver: zodResolver(generalInfoSchema),
  });

  const fieldName = errors["name"] as any;
  const fieldShortName = errors["shortName"] as any;
  const fieldUrlPath = errors["urlPath"] as any;
  const fieldDescription = errors["description"] as any;

  const [createNew, { isLoading, isError, error, isSuccess, data }] =
    useAddGeneralInfoMutation();

  useEffect(() => {
    if (isSuccess) {
      handleNext();
    }
    if (isError) {
      if (Array.isArray((error as any).data.error)) {
        (error as any).data.error.forEach((el: any) =>
          toast.error(el.message, {
            position: "top-right",
          }),
        );
      } else {
        toast.error((error as any).data.message, {
          position: "top-right",
        });
      }
    }
  }, [isLoading]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful]);

  const onSubmitHandler: SubmitHandler<GeneralInfoInput> = ({
    name,
    shortName,
    urlPath,
    description,
  }) => {
    createNew({ name, shortName, urlPath, description });
  };

  return (
    <Box
      height="400px"
      sx={{
        position: "relative",
        paddingTop: "30px",
        margin: "0 20px 0 20px",
      }}
    >
      <Typography
        sx={{
          padding: "20px 0 10px 0",
          fontWeight: "500",
        }}
      >
        Enter a few business details to get started
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmitHandler)}
        noValidate
        autoComplete="off"
      >
        <FormControl variant="standard" fullWidth>
          <TextField
            sx={{
              width: "60%",
            }}
            required
            variant="standard"
            size="small"
            label="Business Name"
            {...register("name")}
          />
          <FormHelperText error={!!errors["name"]}>
            {fieldName ? fieldName.message : ""}
          </FormHelperText>
        </FormControl>
        <StepButton activeStep={activeStep} lastStep={lastStep} />
      </Box>
    </Box>
  );
};

export default GeneralInfo;
