import { FormHelperText, FormControl, InputProps } from "@mui/material";
import React, { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";

type IFormInputProps = {
  name: string;
  label: string;
} & InputProps;

const FormInput: FC<IFormInputProps> = ({ name, label, ...otherProps }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const fieldName = errors[name] as any;

  return (
    <Controller
      control={control}
      defaultValue=""
      name={name}
      render={({ field }) => (
        <FormControl
          variant="outlined"
          fullWidth
          sx={{
            pb: "15px",
          }}
        >
          <InputLabel htmlFor={label}>{label}</InputLabel>
          <OutlinedInput
            {...field}
            fullWidth
            error={!!errors[name]}
            required
            {...otherProps}
            endAdornment={<InputAdornment position="end"></InputAdornment>}
            label={label}
            autoComplete="off"
          />
          <FormHelperText error={!!errors[name]}>
            {fieldName ? fieldName.message : ""}
          </FormHelperText>
        </FormControl>
      )}
    />
  );
};

export default FormInput;
