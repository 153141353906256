import React from "react";
import { Box, Typography } from "@mui/material";
import "./steps.css";
import { steps } from "./steps";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";

const HowItWorks = () => {
  return (
    <Box
      key="steps"
      sx={{
        width: { xs: "100%", sm: "70%", md: "60%" },
        paddingTop: "70px",
        paddingBottom: "70px",
        margin: "20px",
        justifyContent: "center",
        alignItems: "center",
        transform: "scale",
      }}
    >
      <Typography
        variant="h4"
        sx={{
          fontWeight: "bold",
          textAlign: "center",
          color: "primary.main",
          paddingBottom: "20px",
        }}
      >
        Build your company webpage in some steps
      </Typography>
      <Timeline position="alternate">
        {steps.map((step) => (
          <TimelineItem key={step.number}>
            <TimelineSeparator key={step.number}>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent
              sx={{
                color: "whitesmoke",
              }}
            >
              <span className="step">
                {step.number}. {step.title}
              </span>{" "}
              <br /> {step.description}
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </Box>
  );
};

export default HowItWorks;
