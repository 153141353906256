import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { AddressSearchInput } from "../../feature/Business/Location/Location";

const BASE_URL = process.env.REACT_APP_API_ENDPOINT as string;

export type Address = {
  fullAddress: string;
  latitude: string;
  longitude: string;
  locationId: string;
};

export const addressApi = createApi({
  reducerPath: "addressApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}`,
  }),
  tagTypes: ["Address"],
  endpoints: (builder) => ({
    searchAddress: builder.query<Address[], AddressSearchInput>({
      query: (input) => {
        return {
          url: `/v1/geocode/search?q=${input.address}`,
          method: "GET",
          credentials: "include",
        };
      },
      transformResponse: (response: Address[]) => {
        return response;
      },
      providesTags: (result, error, address) => [{ type: "Address", address }],
    }),
  }),
});

export const { useSearchAddressQuery } = addressApi;
