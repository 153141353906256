import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { LoginInput } from "../../feature/User/Login";
import { RegisterInput } from "../../feature/User/Register";
import { userApi } from "./user";

const BASE_URL = process.env.REACT_APP_API_ENDPOINT as string;

export type GenericResponse = {
  status: string;
  message: string;
};

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}`,
  }),
  endpoints: (builder) => ({
    registerUser: builder.mutation<GenericResponse, RegisterInput>({
      query(data) {
        return {
          url: "/v1/users",
          method: "POST",
          body: data,
        };
      },
    }),
    loginUser: builder.mutation<
      { access_token_expiration: string },
      LoginInput
    >({
      query(data) {
        return {
          url: "/v1/users/login",
          method: "POST",
          body: data,
          credentials: "include",
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          await dispatch(userApi.endpoints.getMe.initiate(null));
        } catch (error) {}
      },
    }),
    verifyEmail: builder.mutation<
      GenericResponse,
      { email: string; otp: string }
    >({
      query({ email, otp }) {
        return {
          url: `/v1/users/confirm-email`,
          method: "POST",
          body: { email, otp },
        };
      },
    }),
    logoutUser: builder.mutation<void, void>({
      query() {
        return {
          url: "/v1/users/logout",
          method: "POST",
          credentials: "include",
        };
      },
    }),
    forgotPassword: builder.mutation<GenericResponse, { email: string }>({
      query({ email }) {
        return {
          url: "/v1/users/forgot-password",
          method: "POST",
          body: { email },
          credentials: "include",
        };
      },
    }),
    resetPassword: builder.mutation<
      GenericResponse,
      { otp: string; password: string; confirm_password: string }
    >({
      query({ otp, password, confirm_password }) {
        return {
          url: "/v1/users/reset-password",
          method: "POST",
          body: { otp, password, confirm_password },
          credentials: "include",
        };
      },
    }),
  }),
});

export const {
  useLoginUserMutation,
  useRegisterUserMutation,
  useLogoutUserMutation,
  useVerifyEmailMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
} = authApi;
