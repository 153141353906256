import * as React from "react";
import { Box, Grid, Link, Typography } from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import "./app-footer.css";

const iconStyle = {
  width: 48,
  height: 48,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "primary.main",
  mr: 1,
  "&:hover": {
    color: "primary.light",
  },
};

export default function AppFooter() {
  return (
    <footer className="footer">
      <Grid container alignContent="center" justifyContent="center">
        <Grid
          item
          display="flex"
          xs={12}
          md={12}
          alignContent="center"
          justifyContent="center"
        >
          <Box
            component="a"
            href="https://www.facebook.com/people/Kiosk-Kit/100088563425107/"
            target="_blank"
            aria-label="Follow Us on Facebook"
            sx={iconStyle}
          >
            <FacebookIcon />
          </Box>
          <Box
            component="a"
            href="https://www.instagram.com/kioskkit/"
            target="_blank"
            aria-label="Follow Us on Instagram"
            sx={iconStyle}
          >
            <InstagramIcon />
          </Box>
          <Box
            component="a"
            href="https://www.linkedin.com/company/kioskkit/"
            target="_blank"
            aria-label="Follow Us on LinkedIn"
            sx={iconStyle}
          >
            <LinkedInIcon />
          </Box>
        </Grid>
        <Grid item xs={12} md={12} textAlign="center">
          <Typography color="white">
            {"© "}
            <Link
              href="/Users/julia/Documents/Learning/kioskkit-client/public"
              underline="none"
              color="white"
            >
              KIOSKKIT
            </Link>{" "}
            {new Date().getFullYear()}. All rights reserved.
          </Typography>
        </Grid>
      </Grid>
    </footer>
  );
}
