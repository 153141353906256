// interface
interface Step {
  number: number;
  title: string;
  description: string;
}

export const steps: Array<Step> = [
  {
    number: 1,
    title: "Register your company. ",
    description: "Create a company account to get the access to all tools.",
  },
  {
    number: 2,
    title: "Add your content. ",
    description: "Create pages, drop in images, and tell your story.",
  },
  {
    number: 3,
    title: "Press publish and promote. ",
    description:
      "Go live and share your business website or online store with the world.",
  },
  {
    number: 4,
    title: "Connect with your audience. ",
    description:
      "Announce new projects or sales with KioskKit Email Campaigns.",
  },
  {
    number: 5,
    title: "Analyze and optimize. ",
    description:
      "Monitor your site’s performance with KioskKit Analytics and adjust your strategy for success.",
  },
];
