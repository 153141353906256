import React from "react";
import { Grid, Typography, Box, Container } from "@mui/material";

const CheckEmail = ({ messageText }: { messageText: string }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        marginTop: 5,
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 5,
      }}
    >
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{ textAlign: "center" }}
        >
          <Typography
            variant="h6"
            sx={{
              color: "#eb5e28",
              fontSize: { xs: "2rem", md: "3rem" },
              fontWeight: 600,
              letterSpacing: 1,
            }}
          >
            Please check your email
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{ textAlign: "center" }}
        >
          <Typography>{messageText}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CheckEmail;
