import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Grid, Typography } from "@mui/material";
import FormInput from "../../components/Shared/FormInput";
import { object, string, TypeOf } from "zod";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForgotPasswordMutation } from "../../app/services/auth";
import { toast } from "react-toastify";

const forgotPasswordSchema = object({
  email: string().min(1, "Email address is required").email("Email is invalid"),
});

export type ForgotPasswordInput = TypeOf<typeof forgotPasswordSchema>;

const ForgotPassword = () => {
  const methods = useForm<ForgotPasswordInput>({
    resolver: zodResolver(forgotPasswordSchema),
  });

  // API Mutation
  const [forgotPassword, { isLoading, isError, error, isSuccess }] =
    useForgotPasswordMutation();

  const navigate = useNavigate();

  const {
    reset,
    handleSubmit,
    formState: { isSubmitSuccessful },
  } = methods;

  useEffect(() => {
    if (isSuccess) {
      toast.success("Email to recover your password was sent.");
      navigate("/check-email");
    }
    if (isError) {
      if (Array.isArray((error as any).data.error)) {
        (error as any).data.error.forEach((el: any) =>
          toast.error(el.message, {
            position: "top-right",
          }),
        );
      } else {
        toast.error((error as any).data.message, {
          position: "top-right",
        });
      }
    }
  }, [isLoading]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful]);

  const onSubmitHandler: SubmitHandler<ForgotPasswordInput> = (values) => {
    forgotPassword(values);
  };

  return (
    <Grid
      container
      spacing={1}
      sx={{
        marginTop: 5,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 5,
      }}
    >
      <Grid item xs={12} sm={6}>
        <Typography
          component="h1"
          sx={{
            textAlign: "center",
            color: "primary.main",
            fontWeight: 600,
            fontSize: { xs: "2rem", md: "3rem" },
            mb: 2,
            letterSpacing: 1,
          }}
        >
          Forgot a password?
        </Typography>

        <Typography textAlign="center" component="h4" sx={{ marginBottom: 2 }}>
          Please enter your email and we will send you a link to reset your
          password.
        </Typography>

        <FormProvider {...methods}>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmitHandler)}
            noValidate
            autoComplete="off"
            sx={{
              maxWidth: "40rem",
              width: "100%",
              p: { xs: "1rem", sm: "2rem" },
              borderRadius: 2,
            }}
          >
            <FormInput name="email" label="Email" type="email" />

            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                alignItems: "end",
              }}
            >
              <Button
                variant="contained"
                fullWidth
                size="large"
                type="submit"
                sx={{
                  width: "30%",
                  whiteSpace: "nowrap",
                  fontWeight: "bold",
                }}
              >
                send
              </Button>
            </Box>
          </Box>
        </FormProvider>
      </Grid>
    </Grid>
  );
};

export default ForgotPassword;
