import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  Typography,
} from "@mui/material";
import { object, string, TypeOf } from "zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "react-toastify";
import { useAddLocationMutation } from "../../../app/services/business";
import StepButton from "../../../components/Shared/StepButton";
import { useAppSelector } from "../../../app/store";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useSearchAddressQuery } from "../../../app/services/address";

const locationSchema = object({
  fullAddress: string().min(1, "Address is required"),
});

export type LocationInput = TypeOf<typeof locationSchema>;

const addressSchema = object({
  address: string().min(1, "Address is required"),
});

export type AddressSearchInput = TypeOf<typeof addressSchema>;

interface ILocationProps {
  activeStep: number;
  lastStep: number;
  handleNext: () => void;
}

type Address = {
  fullAddress: string;
  latitude: string;
  longitude: string;
  locationId: string;
};

const BusinessLocation = ({
  activeStep,
  lastStep,
  handleNext,
}: ILocationProps) => {
  const business = useAppSelector((state) => state.businessState.business);

  const {
    reset,
    register,
    handleSubmit,
    formState: { isSubmitSuccessful, errors },
  } = useForm<LocationInput>({
    resolver: zodResolver(locationSchema),
  });

  const [value, setValue] = useState<Address | null>(null);
  const [fullAddress, setFullAddress] = useState("");
  const [latitudeValue, setLatitude] = useState("");
  const [longitudeValue, setLongitude] = useState("");
  const [locationIdValue, setLocationId] = useState("");
  const [options, setOptions] = useState<readonly Address[]>([]);

  const fieldFullAddress = errors["fullAddress"] as any;

  const [addLocation, { isLoading, isSuccess, isError, error }] =
    useAddLocationMutation();

  const { data = [] } = useSearchAddressQuery(
    { address: fullAddress },
    {
      refetchOnMountOrArgChange: true,
      skip: fullAddress.length < 3,
    },
  );

  const onInputChange = (event: any, newInputValue: string) => {
    setFullAddress(newInputValue);
    setOptions(data);
  };

  useEffect(() => {
    if (isSuccess) {
      handleNext();
    }
    if (isError) {
      if (Array.isArray((error as any).data.error)) {
        (error as any).data.error.forEach((el: any) =>
          toast.error(el.message, {
            position: "top-right",
          }),
        );
      } else {
        toast.error((error as any).data.message, {
          position: "top-right",
        });
      }
    }
  }, [isLoading]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful]);

  const onSubmitHandler: SubmitHandler<LocationInput> = ({ fullAddress }) => {
    const businessId = business !== null ? business.id : "";

    addLocation({
      businessId,
      fullAddress,
      latitude: latitudeValue,
      longitude: longitudeValue,
      locationId: locationIdValue,
    });
  };

  return (
    <Box
      height="400px"
      sx={{
        position: "relative",
        paddingTop: "30px",
        margin: "0 20px 40px 20px",
      }}
    >
      <Typography
        sx={{
          padding: "20px 0 10px 0",
          fontWeight: "500",
        }}
      >
        Location:
      </Typography>
      <Box
        key="businessAddress"
        component="form"
        onSubmit={handleSubmit(onSubmitHandler)}
        noValidate
        autoComplete="off"
      >
        <FormControl variant="standard" fullWidth>
          <Autocomplete
            id="addressAutocompleteInput"
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.fullAddress
            }
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={value}
            noOptionsText="No locations"
            onChange={(event: any, newValue: Address | null) => {
              setOptions(newValue ? [newValue, ...options] : options);
              setValue(newValue);
              if (newValue) {
                setLatitude(newValue?.latitude);
                setLongitude(newValue?.longitude);
                setLocationId(newValue?.locationId);
              }
            }}
            onInputChange={onInputChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Add a location"
                fullWidth
                variant="standard"
                size="small"
                {...register("fullAddress")}
              />
            )}
            renderOption={(props, option) => {
              return (
                <li {...props}>
                  <Grid container alignItems="center">
                    <Grid item sx={{ display: "flex", width: 44 }}>
                      <LocationOnIcon sx={{ color: "text.secondary" }} />
                    </Grid>
                    <Grid
                      item
                      sx={{
                        width: "calc(100% - 44px)",
                        wordWrap: "break-word",
                      }}
                    >
                      <Typography variant="body2" color="text.secondary">
                        {option.fullAddress}
                      </Typography>
                    </Grid>
                  </Grid>
                </li>
              );
            }}
          />
          <FormHelperText error={!!errors["fullAddress"]}>
            {fieldFullAddress ? fieldFullAddress.message : ""}
          </FormHelperText>
        </FormControl>
        <StepButton activeStep={activeStep} lastStep={lastStep} />
      </Box>
    </Box>
  );
};

export default BusinessLocation;
