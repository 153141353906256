import React from "react";
import {
  AppBar,
  Tabs,
  Tab,
  Box,
  Toolbar,
  Typography,
  Link,
} from "@mui/material";
import AccountMenu from "../AccountMenu/AccountMenu";
import "./tabbar.css";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TabBar = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div>
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          backgroundColor: "white",
        }}
      >
        <Toolbar
          sx={{
            color: "primary.main",
            borderBottom: 1,
            borderColor: "divider",
          }}
        >
          <Box
            sx={{
              display: "flex",
              paddingRight: "20px",
            }}
          >
            <Link href="" underline="none">
              <div className="logo-link"></div>
            </Link>
          </Box>
          <Box display="flex">
            <Tabs value={value} onChange={handleChange}>
              <Tab
                label="Dashboard"
                sx={{
                  lineHeight: "50px",
                  textTransform: "none",
                }}
                {...a11yProps(0)}
              />
              <Tab
                label="Reviews"
                sx={{
                  lineHeight: "50px",
                  textTransform: "none",
                }}
                {...a11yProps(1)}
              />
            </Tabs>
          </Box>
          <Box sx={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
            <AccountMenu />
          </Box>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default TabBar;
