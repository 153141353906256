import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../app/store";
import { useForm } from "react-hook-form";
import { useAddCategoryMutation } from "../../../app/services/business";
import { useGetAllCategoriesQuery } from "../../../app/services/categories";
import { toast } from "react-toastify";
import {
  Box,
  Chip,
  FormControl,
  FormHelperText,
  Input,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import StepButton from "../../../components/Shared/StepButton";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useNavigate } from "react-router-dom";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

interface ICategoryProps {
  activeStep: number;
  lastStep: number;
  handleNext: () => void;
}

type SubCategory = {
  id: number;
  name: string;
};

const Category = ({ activeStep, lastStep, handleNext }: ICategoryProps) => {
  const navigate = useNavigate();
  const business = useAppSelector((state) => state.businessState.business);
  const [subCategories, setSubcategories] = useState<SubCategory[]>([]);
  const [categoryIds, setCategoryIds] = React.useState<number[]>([]);

  const {
    reset,
    register,
    handleSubmit,
    formState: { isSubmitSuccessful, errors },
  } = useForm();

  const fieldSubCategory = errors["subCategories"] as any;

  const [addCategory, { isLoading, isSuccess, isError, error }] =
    useAddCategoryMutation();

  const { data } = useGetAllCategoriesQuery({
    refetchOnMountOrArgChange: false,
    skip: false,
  });

  const categories: any = [];
  useEffect(() => {
    if (data) {
      Object.keys(data).forEach((key: string) => {
        categories.push({ label: key });
      });
    }
  });

  useEffect(() => {
    if (isSuccess) {
      toast.success("Business was created.");
      if (business !== null) {
        navigate("business/details/" + business.id, { replace: true });
      }
    }
    if (isError) {
      if (Array.isArray((error as any).data.error)) {
        (error as any).data.error.forEach((el: any) =>
          toast.error(el.message, {
            position: "top-right",
          }),
        );
      } else {
        toast.error((error as any).data.message, {
          position: "top-right",
        });
      }
    }
  }, [isLoading]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful]);

  const [subCategory, setSubcategory] = React.useState<string[]>([]);

  const handleChange = (
    event: SelectChangeEvent<typeof subCategory>,
    obj: any,
  ) => {
    const {
      target: { value },
    } = event;
    addSubCategoryId(obj.key);
    setSubcategory(typeof value === "string" ? value.split(",") : value);
  };

  const addSubCategoryId = (subCategoryStr: string) => {
    const categoryStr = subCategoryStr.replace(".$", "");
    const categoryId: number = +categoryStr;
    categoryIds.push(categoryId);
    setCategoryIds(categoryIds);
  };

  const onCategoryInputChange = (event: any, newInputValue: string) => {
    if (newInputValue) {
      setSubcategories(data[newInputValue]);
    } else {
      setCategoryIds([]);
      setSubcategory([]);
      setSubcategories([]);
    }
  };

  // Submit
  function onSubmitHandler() {
    const businessId = business !== null ? business.id : "";

    if (categoryIds.length > 0) {
      addCategory({
        businessId,
        categoryIds,
      });
    }
  }

  return (
    <Box
      height="400px"
      sx={{
        position: "relative",
        paddingTop: "30px",
        margin: "0 20px 0 20px",
      }}
    >
      <Typography
        sx={{
          padding: "20px 0 10px 0",
          fontWeight: "500",
        }}
      >
        Business Category:
      </Typography>
      <Box
        key="businessCategory"
        component="form"
        onSubmit={handleSubmit(onSubmitHandler)}
        noValidate
        autoComplete="off"
      >
        <FormControl variant="standard" fullWidth margin="dense">
          <Autocomplete
            disablePortal
            id="parent-category"
            options={categories}
            onInputChange={onCategoryInputChange}
            filterOptions={(x) => x}
            isOptionEqualToValue={(option: any, value: any) =>
              option.name === value.name
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Category"
                fullWidth
                variant="standard"
              />
            )}
          />
        </FormControl>
        <FormControl variant="standard" fullWidth margin="dense">
          <Select
            labelId="multiple-chip-label"
            id="multiple-chip"
            multiple
            value={subCategory}
            {...register("subCategories", {
              required: "SubCategory is required.",
            })}
            name="subCategories"
            onChange={handleChange}
            input={<Input id="select-multiple-chip" fullWidth />}
            renderValue={(selected: any) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value: any) => (
                  <Chip key={value} label={value} />
                ))}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {subCategories.map((sc) => (
              <MenuItem key={sc.id} value={sc.name}>
                {sc.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText error={!!errors["subCategories"]}>
            {fieldSubCategory ? fieldSubCategory.message : ""}
          </FormHelperText>
        </FormControl>
        <StepButton activeStep={activeStep} lastStep={lastStep} />
      </Box>
    </Box>
  );
};

export default Category;
