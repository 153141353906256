import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setBusiness } from "../../feature/Business/businessSlice";
import { GeneralInfoInput } from "../../feature/Business/GeneralInfo/GeneralInfo";

const BASE_URL = process.env.REACT_APP_API_ENDPOINT as string;

export type Business = {
  id: string;
  name: string;
  shortName: string;
  description: string;
  urlPath: string;
  registrationStep: string;
  status: string;
};

export type BusinessLogo = {
  businessId: string;
  file: FormData | null;
};

export type BusinessLocation = {
  businessId: string;
  fullAddress: string;
  latitude: string;
  longitude: string;
  locationId: string;
};

export type BusinessContacts = {
  businessId: string;
  phone: string;
  email: string;
  webUrl: string;
  facebookUrl: string;
  instagramUrl: string;
};

export type BusinessCategories = {
  businessId: string;
  categoryIds: number[];
};

export const businessApi = createApi({
  reducerPath: "businessApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}`,
  }),
  tagTypes: ["Business"],
  endpoints: (builder) => ({
    addGeneralInfo: builder.mutation<Business, GeneralInfoInput>({
      query(data) {
        return {
          url: "/v1/businesses",
          method: "POST",
          body: data,
          credentials: "include",
        };
      },
      transformResponse: (result: Business) => {
        return result;
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setBusiness(data));
        } catch (error) {}
      },
    }),
    addLogo: builder.mutation<Business, BusinessLogo>({
      query(data) {
        return {
          url: `/v1/businesses/${data.businessId}/logos`,
          method: "POST",
          body: data.file,
          credentials: "include",
        };
      },
      transformResponse: (result: Business) => {
        return result;
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setBusiness(data));
        } catch (error) {}
      },
    }),
    addLocation: builder.mutation<Business, BusinessLocation>({
      query(data) {
        return {
          url: `/v1/businesses/${data.businessId}/addresses`,
          method: "POST",
          body: data,
          credentials: "include",
        };
      },
      transformResponse: (result: Business) => {
        return result;
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setBusiness(data));
        } catch (error) {}
      },
    }),
    addContacts: builder.mutation<Business, BusinessContacts>({
      query(data) {
        return {
          url: `/v1/businesses/${data.businessId}/contacts`,
          method: "POST",
          body: data,
          credentials: "include",
        };
      },
      transformResponse: (result: Business) => {
        return result;
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setBusiness(data));
        } catch (error) {}
      },
    }),
    addCategory: builder.mutation<Business, BusinessCategories>({
      query(data) {
        return {
          url: `/v1/businesses/${data.businessId}/categories`,
          method: "POST",
          body: data,
          credentials: "include",
        };
      },
      transformResponse: (result: Business) => {
        return result;
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setBusiness(data));
        } catch (error) {}
      },
    }),
  }),
});

export const {
  useAddGeneralInfoMutation,
  useAddLogoMutation,
  useAddLocationMutation,
  useAddContactsMutation,
  useAddCategoryMutation,
} = businessApi;
