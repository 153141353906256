import React, { createRef, useEffect, useState } from "react";
import {
  Box,
  FormControl,
  FormHelperText,
  Slider,
  Stack,
  Typography,
} from "@mui/material";
import StepButton from "../../../components/Shared/StepButton";
import "react-image-crop/dist/ReactCrop.css";
import ReactAvatarEditor from "react-avatar-editor";
import logo from "../../../images/logo.webp";
import { useForm } from "react-hook-form";
import { useAddLogoMutation } from "../../../app/services/business";
import { toast } from "react-toastify";
import { useAppSelector } from "../../../app/store";

interface ILogoProps {
  activeStep: number;
  lastStep: number;
  handleNext: () => void;
}

const Logo = ({ activeStep, lastStep, handleNext }: ILogoProps) => {
  const business = useAppSelector((state) => state.businessState.business);

  // const editor = useRef(null);
  const editorRef: React.RefObject<ReactAvatarEditor> = createRef();
  const [image, setImage] = useState(logo);
  const [position, setPosition] = useState({ x: 0.5, y: 0.5 });
  const [scale, setScale] = useState(1);
  const [logoFile, setLogoFile] = useState<File>();

  const {
    reset,
    register,
    handleSubmit,
    formState: { isSubmitSuccessful, errors },
  } = useForm();

  const fieldName = errors["fileName"] as any;

  const [addLogo, { isLoading, isError, error, isSuccess }] =
    useAddLogoMutation();

  // handleNewImage = e => {
  //   this.setState({ image: e.target.files[0] })
  // };
  //
  // handleScale = e => {
  //   const scale = parseFloat(e.target.value)
  //   this.setState({ scale })
  // };
  //
  // handlePositionChange = position => {
  //   this.setState({ position })
  // };

  const handleNewImage = async (e: any) => {
    await setImage(e.target.files[0]);
    await setLogoFile(e.target.files[0]);
  };

  const handleScale = (e: any) => {
    const scale = parseFloat(e.target.value);
    setScale(scale);
  };

  const handlePositionChange = (position: any) => {
    setPosition(position);
  };

  useEffect(() => {
    if (isSuccess) {
      handleNext();
    }
    if (isError) {
      if (Array.isArray((error as any).data.error)) {
        (error as any).data.error.forEach((el: any) =>
          toast.error(el.message, {
            position: "top-right",
          }),
        );
      } else {
        toast.error((error as any).data.message, {
          position: "top-right",
        });
      }
    }
  }, [isLoading]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful]);

  // https://codesandbox.io/s/react-avatar-editor1107-1t7lb?file=/src/index.js:442-464
  // https://codesandbox.io/s/q0zo6
  // https://codesandbox.io/s/old-snow-fkw3l?file=/src/Demo.tsx

  //https://codesandbox.io/s/react-avatar-editor-demo-fw61k?file=/src/App.tsx:3120-3129
  const onSubmitHandler = async (data: any) => {
    if (editorRef.current) {
      // This returns a HTMLCanvasElement, it can be made into a data URL or a blob,
      // drawn on another canvas, or added to the DOM.

      // const canvas = editor.current?.getImage();

      // If you want the image resized to the canvas size (also a HTMLCanvasElement)
      const canvasScaled = editorRef.current.getImageScaledToCanvas();

      //if (editor) {
      // This returns a HTMLCanvasElement, it can be made into a data URL or a blob,
      // drawn on another canvas, or added to the DOM.
      //const img = editor.getImageScaledToCanvas().toDataURL();
      //const img = editor.current?.toDataURL();
      //console.log(img);
      //}

      //if (editor) {
      // This returns a HTMLCanvasElement, it can be made into a data URL or a blob,
      // drawn on another canvas, or added to the DOM.
      // const currentEditor = editor.current;
      // console.log(currentEditor);
      // if (currentEditor) {
      //  const canvas = (currentEditor as HTMLCanvasElement).getImage();
      // }

      // If you want the image resized to the canvas size (also a HTMLCanvasElement)
      //const canvasScaled = editor.current.getImageScaledToCanvas();
      //}

      fetch(canvasScaled.toDataURL(logoFile?.type))
        .then((res) => res.blob())
        .then((blob) => {
          const data = new FormData();
          data.append("file", blob, logoFile?.name);

          const businessId = business !== null ? business.id : "";
          addLogo({ businessId, file: data });
        });

      // canvasScaled.toBlob((blob: string | Blob) => {
      //   const data = new FormData();
      //   data.append("file", blob, logoFile?.name);
      //
      //   const businessId = business !== null ? business.id : "";
      //   addLogo({ businessId, file: data });
      // }, logoFile?.type);

      // https://github.com/mosch/react-avatar-editor/issues/245
      // const formData = new FormData();
      // formData.append("file", logoFile as File);
      //
      // const businessId = business !== null ? business.id : "";
      // addLogo({ businessId, file: formData });
    }
  };

  // const setEditorRef = (editor: any) => (editor = editor);

  return (
    <Box
      height="400px"
      sx={{
        position: "relative",
        paddingTop: "30px",
        margin: "0 20px 40px 20px",
      }}
    >
      <Typography
        sx={{
          padding: "20px 0 10px 0",
          fontWeight: "500",
        }}
      >
        Page Logo:
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmitHandler)}
        noValidate
        autoComplete="off"
        sx={{
          width: "150px",
          justifyContent: "center",
        }}
      >
        <FormControl variant="standard" fullWidth>
          <input
            required
            accept="image/*"
            type="file"
            {...register("fileName")}
            onChange={handleNewImage}
          />
          <FormHelperText error={!!errors["fileName"]}>
            {fieldName ? fieldName.message : ""}
          </FormHelperText>
        </FormControl>
        <Box key="canvasLogo">
          <ReactAvatarEditor
            //ref={setEditorRef}
            ref={editorRef}
            image={image}
            width={150}
            height={150}
            border={20}
            borderRadius={150}
            color={[255, 255, 255, 1]}
            scale={parseFloat(String(scale))}
            position={position}
            onPositionChange={handlePositionChange}
            rotate={0}
          />
        </Box>
        <Stack>
          <Slider
            onChange={handleScale}
            size="small"
            value={scale}
            min={1}
            step={0.05}
            defaultValue={1}
          />
        </Stack>
        <StepButton activeStep={activeStep} lastStep={lastStep} />
      </Box>
    </Box>
  );
};

export default Logo;
