import React from "react";
import { Box, Button } from "@mui/material";

interface IStepButtonProps {
  activeStep: number;
  lastStep: number;
}

const StepButton = ({ activeStep, lastStep }: IStepButtonProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        position: "absolute",
        justifyContent: "end",
        paddingTop: "30px",
        textAlign: "right",
        bottom: 0,
        right: 0,
      }}
    >
      <Button variant="contained" type="submit">
        {activeStep === lastStep ? "Finish" : "Continue"}
      </Button>
    </Box>
  );
};

export default StepButton;
