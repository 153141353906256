interface Step {
  title: string;
}

export const steps: Array<Step> = [
  {
    title: "General Info",
  },
  {
    title: "Logo",
  },
  {
    title: "Location",
  },
  {
    title: "Contacts",
  },
  {
    title: "Categories",
  },
];
