import React, { useEffect } from "react";
import { Box, FormControl, Grid, TextField, Typography } from "@mui/material";
import { object, string, TypeOf } from "zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "react-toastify";
import { useAddContactsMutation } from "../../../app/services/business";
import StepButton from "../../../components/Shared/StepButton";
import { useAppSelector } from "../../../app/store";

const contactsSchema = object({
  phone: string().min(1, "Phone Number is required"),
  email: string().min(1, "Email is required"),
  webUrl: string(),
  facebookUrl: string(),
  instagramUrl: string(),
});

export type ContactsInput = TypeOf<typeof contactsSchema>;

interface IContactsProps {
  activeStep: number;
  lastStep: number;
  handleNext: () => void;
}

const Contacts = ({ activeStep, lastStep, handleNext }: IContactsProps) => {
  const {
    reset,
    register,
    handleSubmit,
    formState: { isSubmitSuccessful },
  } = useForm<ContactsInput>({
    resolver: zodResolver(contactsSchema),
  });

  const business = useAppSelector((state) => state.businessState.business);

  const [createNew, { isLoading, isSuccess, isError, error }] =
    useAddContactsMutation();

  useEffect(() => {
    if (isSuccess) {
      handleNext();
    }
    if (isError) {
      if (Array.isArray((error as any).data.error)) {
        (error as any).data.error.forEach((el: any) =>
          toast.error(el.message, {
            position: "top-right",
          }),
        );
      } else {
        toast.error((error as any).data.message, {
          position: "top-right",
        });
      }
    }
  }, [isLoading]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful]);

  const onSubmitHandler: SubmitHandler<ContactsInput> = ({
    phone,
    email,
    webUrl,
    facebookUrl,
    instagramUrl,
  }) => {
    const businessId = business !== null ? business.id : "";

    createNew({
      businessId,
      phone,
      email,
      webUrl,
      facebookUrl,
      instagramUrl,
    });
  };

  return (
    <Box
      height="400px"
      sx={{
        position: "relative",
        paddingTop: "30px",
        margin: "0 20px 0 20px",
      }}
    >
      <Typography
        sx={{
          padding: "20px 0 10px 0",
          fontWeight: "500",
        }}
      >
        Contacts:
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmitHandler)}
        noValidate
        autoComplete="off"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <FormControl variant="standard" fullWidth margin="dense">
              <TextField
                required
                variant="standard"
                size="small"
                type="tel"
                {...register("phone")}
                label="Business Phone Number"
              />
            </FormControl>
            <FormControl variant="standard" fullWidth margin="dense">
              <TextField
                required
                variant="standard"
                size="small"
                type="email"
                {...register("email")}
                label="Business Email"
              />
            </FormControl>
            <FormControl variant="standard" fullWidth margin="dense">
              <TextField
                variant="standard"
                size="small"
                {...register("webUrl")}
                label="Web Site URL"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <FormControl variant="standard" fullWidth margin="dense">
              <TextField
                variant="standard"
                size="small"
                {...register("instagramUrl")}
                label="Instagram"
              />
            </FormControl>
            <FormControl variant="standard" fullWidth margin="dense">
              <TextField
                variant="standard"
                size="small"
                {...register("facebookUrl")}
                label="Facebook"
              />
            </FormControl>
          </Grid>
        </Grid>
        <StepButton activeStep={activeStep} lastStep={lastStep} />
      </Box>
    </Box>
  );
};

export default Contacts;
