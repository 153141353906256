import paella from "../../images/template.png";

interface WebPage {
  title: string;
  subtitle: string;
  image: string;
  altImage: string;
  description: string;
}

export const businessList: Array<WebPage> = [
  {
    title: "Your Web Page 1",
    subtitle: "Test subtitle",
    image: paella,
    altImage: "Test 1",
    description: "This is test page description 1.",
  },
  {
    title: "My First Web Page 2",
    subtitle: "Test subtitle",
    image: paella,
    altImage: "Test 2",
    description: "This is test page 2.",
  },
  {
    title: "My First Web Page 3",
    subtitle: "Test subtitle",
    image: paella,
    altImage: "Test 3",
    description: "This is test page 3.",
  },
];
