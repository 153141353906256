import { createTheme } from "@mui/material/styles";
import indigo from "@mui/material/colors/indigo";
import amber from "@mui/material/colors/deepOrange";

export const theme = createTheme({
  palette: {
    primary: amber,
    secondary: indigo,
  },
  typography: {
    fontFamily: `Clarkson, "Helvetica Neue", Helvetica, Arial, sans-serif`,
  },
  mixins: {
    toolbar: {
      minHeight: 70,
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        maxWidthLg: {
          "&.MuiContainer-maxWidthLg": {
            maxWidth: "100vw",
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        formControl: {
          "&.MuiInputBase-formControl": {
            color: "#ff5722",
          },
        },
      },
    },
  },
});
