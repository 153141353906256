import React from "react";
import { Route, Routes } from "react-router-dom";
import { withCookies } from "react-cookie";
import { routes as appRoutes } from "./routes";
import Home from "./components/Home/Home";
import RequireUser from "./feature/User/RequireUser";
import Dashboard from "./feature/Account/Dashboard";
import VerifyEmail from "./feature/User/VerifyEmail";
import ForgotPassword from "./feature/User/ForgotPassword";
import CheckEmail from "./feature/User/CheckEmail";
import PasswordRecovery from "./feature/User/PasswordRecovery";
import Profile from "./feature/User/Profile";
import { CssBaseline } from "@mui/material";
import { ToastContainer } from "react-toastify";
import NotFound from "./components/NotFoundPage/NotFound";
import HomeLayout from "./components/Shared/HomeLayout";
import Layout from "./components/Shared/Layout";
import Create from "./feature/Business/Create/Create";
import BusinessDetails from "./feature/Business/Details/BusinessDetails";
import JoinUsLater from "./components/JoinUsLater/JoinUsLater";

function App() {
  return (
    <>
      <CssBaseline />
      <ToastContainer />
      <Routes>
        <>
          <Route path="/" element={<HomeLayout />}>
            <Route index key="home" element={<Home />} />
            <Route element={<RequireUser allowedRoles={["Person"]} />}>
              <Route path="profile" element={<Profile />} />
            </Route>
            <Route path="users/confirm-email" element={<VerifyEmail />} />
            <Route
              path="confirm-email"
              element={
                <CheckEmail messageText="We sent you an email with a confirmation code." />
              }
            />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route
              path="check-email"
              element={
                <CheckEmail messageText="Use the link in email to recover a password." />
              }
            />
            <Route path="users/reset-password" element={<PasswordRecovery />}>
              <Route path=":otp" element={<PasswordRecovery />} />
            </Route>
            <Route path="notify-me" element={<JoinUsLater />}></Route>
            {appRoutes.map((route) => (
              <Route
                key={route.key}
                path={route.path}
                element={<route.component />}
              />
            ))}
          </Route>
          <Route path="/" element={<Layout />}>
            <Route path="dashboard" element={<Dashboard />} />
            {/*<Route element={<RequireUser allowedRoles={["Person"]} />}></Route>*/}
            <Route path="business/create" element={<Create />} />
            <Route path="business/details/" element={<BusinessDetails />}>
              <Route path=":id" element={<BusinessDetails />} />
            </Route>
          </Route>
        </>
        <Route path="/" element={<HomeLayout />}>
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </>
  );
}

export default withCookies(App);
