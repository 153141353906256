import React from "react";
import { Box, Typography } from "@mui/material";

const NotFound = () => {
  return (
    <Box textAlign="center" height="100vh">
      <Typography
        color="primary.main"
        sx={{
          fontWeight: "bold",
          fontSize: "5vw",
          position: "absolute",
          top: "50%",
          left: "15%",
        }}
      >
        Oops... page was not found
      </Typography>
    </Box>
  );
};

export default NotFound;
