import React, { FC, ReactElement, useRef } from "react";
import { Box } from "@mui/material";
import "./home.css";
import Starter from "../Starter/Starter";
import Benefits from "../Benefits/Benefit";
import HowItWorks from "../HowItWorks/HowItWorks";

const scrollToRef = (ref: any) => window.scrollTo(0, ref.current.offsetTop);

const Home: FC<any> = (): ReactElement => {
  const goToBenefits = useRef(null);
  const benefitsScroll = () => scrollToRef(goToBenefits);

  return (
    <Box>
      <Box>
        <Starter onClick={benefitsScroll} />
      </Box>
      <Box>
        <Benefits ref={goToBenefits} />
      </Box>
      <Box
        sx={{
          backgroundColor: "#0f151b",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <HowItWorks />
      </Box>
    </Box>
  );
};

export default Home;
