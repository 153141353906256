import React from "react";
import { Container } from "@mui/material";
import { Outlet } from "react-router-dom";
import TabBar from "../TabBar/TabBar";
import AppFooter from "../AppFooter/AppFooter";
import { ThemeProvider } from "@mui/material";
import { theme } from "../../theme";
import "react-toastify/dist/ReactToastify.css";

const Layout = () => {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <TabBar />
        <Container
          disableGutters
          sx={{
            paddingTop: "100px",
            width: "90%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Outlet />
        </Container>
        <AppFooter />
      </ThemeProvider>
    </div>
  );
};

export default Layout;
