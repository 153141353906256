import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

const BASE_URL = process.env.REACT_APP_API_ENDPOINT as string;

export type SubCategory = {
  id: number;
  name: string;
};

export const categoryApi = createApi({
  reducerPath: "categoryApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}`,
  }),
  tagTypes: ["Categories"],
  endpoints: (builder) => ({
    getAllCategories: builder.query({
      query() {
        return {
          url: "/v1/business-categories",
          method: "GET",
          credentials: "include",
        };
      },
      transformResponse: (result: any) => {
        return result;
      },
      /*async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setCategory(data));
        } catch (error) {
          console.log(error);
        }
      },*/
    }),
  }),
});

export const { useGetAllCategoriesQuery } = categoryApi;
